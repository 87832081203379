import { Dialog, DialogProps, Fade } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { MouseEvent, VFC } from "react";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    borderRadius: theme.shape.borderRadius * 4,
    backgroundColor: theme.colors.white,
  },
}));

const Transition = (props) => <Fade {...props} />;

export type ModalCloseHandler =
  | ((event: MouseEvent<HTMLElement>, reason?: "backdropClick" | "escapeKeyDown") => void)
  | ((e: MouseEvent<HTMLElement>) => void);

export type ModalProps = Omit<DialogProps, "Backdrop" | "closeAfterTransition" | "children" | "onClose"> & {
  /**
   * Modal children, if a function will only mount if open is true
   */
  children?: DialogProps["children"] | (() => DialogProps["children"]);
  onClose?: ModalCloseHandler;
};

export const Modal: VFC<ModalProps> = ({ open, children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={!!open}
      className={clsx(classes.root, className)}
      PaperProps={{
        className: classes.paper,
      }}
      TransitionComponent={Transition}
      closeAfterTransition
      {...rest}
    >
      {(() => {
        switch (typeof children) {
          case "function":
            return !!open && children();
          default:
            return children;
        }
      })()}
    </Dialog>
  );
};
