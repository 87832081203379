import { atom, useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useModal } from "mui-modal-provider";
import { ActiveQuest, QuestGroup, QuestsProgress, QuestStep } from "../../components/quests/quests.types";
import {
  getNextQuestStep,
  getQuestConfigFromActiveQuest,
  getQuestsStepConfig,
} from "../../components/quests/quests.util";
import { QuestVideoPlayerModal } from "../../components/quests/QuestVideoPlayerModal";
import { LOCAL_STORAGE_ACTIVE_QUESTS } from "../../consts/storageKeys";
import { reclaim } from "../../reclaim-api";
import { useCallbackSafeRef } from "../useCallbackSafeRef";
import { useNotifications } from "../useNotifications";
import { useOurRouter } from "../useOurRouter";

const activeQuestAtom = atomWithStorage<ActiveQuest<QuestGroup> | undefined>(LOCAL_STORAGE_ACTIVE_QUESTS, undefined);
const questsAtom = atom<QuestsProgress | undefined>(undefined);
const loadingQuestsAtom = atom<boolean>(false);

const loadingErrorAtom = atom<Error | undefined>(undefined);

export type UseQuestsStateReturnType = {
  activeQuest?: ActiveQuest<QuestGroup>;
  quests?: QuestsProgress;
  questsLoading: boolean;
  loadingError?: Error;
};

export const useQuestsState = (): UseQuestsStateReturnType => {
  const [quests] = useAtom(questsAtom);
  const [activeQuest] = useAtom(activeQuestAtom);
  const [questsLoading] = useAtom(loadingQuestsAtom);
  const [loadingError] = useAtom(loadingErrorAtom);

  return { activeQuest, quests, questsLoading, loadingError };
};

export type UseQuestsActionsReturnType = {
  loadQuests: () => Promise<QuestsProgress | undefined>;
  updateQuests: (quests: QuestsProgress) => void;
  setActiveQuest: (quest: ActiveQuest<QuestGroup> | undefined) => void;
  completeQuest: (quest: ActiveQuest<QuestGroup>) => void;
  onStepComplete: (step: QuestStep) => void;
};

export const useQuestsActions = (): UseQuestsActionsReturnType => {
  const [activeQuest, _setActiveQuest] = useAtom(activeQuestAtom);
  const [quests, setQuests] = useAtom(questsAtom);
  const [, setQuestsLoading] = useAtom(loadingQuestsAtom);
  const [, setLoadingError] = useAtom(loadingErrorAtom);

  const router = useOurRouter();

  const { sendNotification } = useNotifications();

  const { showModal } = useModal({ disableAutoDestroy: true });

  const loadQuests = useCallbackSafeRef(async () => {
    setQuestsLoading(true);

    try {
      const quests = await reclaim.users.getCompletedQuests();
      setQuests(quests);
      void setLoadingError(undefined);
      return quests;
    } catch (cause) {
      const message = "Failed to get setup guide progress.";
      void setLoadingError(new Error(message, { cause }));
      sendNotification({
        message: `${message} Please contact support if this problem persists.`,
        type: "error",
      });
    } finally {
      setQuestsLoading(false);
    }
  });

  const updateQuests = useCallbackSafeRef((quests: QuestsProgress) => {
    setQuests(quests);
  });

  const completeQuest = useCallbackSafeRef(async (activeQuest: ActiveQuest<QuestGroup>) => {
    _setActiveQuest(undefined);
    const quest = getQuestConfigFromActiveQuest(activeQuest);
    const questComplete = !!quests?.[activeQuest.group]?.quests?.[activeQuest.quest]?.complete;

    if (!quest?.indeterminant && !questComplete) {
      try {
        const newQuests = await reclaim.users.completeQuest(activeQuest.quest);
        setQuests(newQuests);
      } catch (cause) {
        sendNotification({
          message: "Failed to save setup guide progress. Please contact support if this problem persists.",
          type: "error",
        });
      }
    }
  });

  const setNextStepAsActive = useCallbackSafeRef((quest: ActiveQuest<QuestGroup>) => {
    const next = getNextQuestStep(quest);
    !!next ? _setActiveQuest({ ...quest, step: next.id }) : void completeQuest(quest);
  });

  const setActiveQuest = useCallbackSafeRef((quest: ActiveQuest<QuestGroup> | undefined) => {
    if (quest) {
      const step = getQuestsStepConfig(quest);
      if (!step) {
        _setActiveQuest(undefined);
        throw new Error("Must provide a quest step");
      } else {
        switch (step.type) {
          case "url": {
            if (!step.url) {
              throw new Error("Must provide a url for url quest step");
            }
            window.open(step.url, "reclaim_slackintegration");
            setNextStepAsActive(quest);
            break;
          }
          case "redirect": {
            if (!step.redirect) {
              throw new Error("Must provide a redirect path for redirect quest step");
            }
            void router.push(step.redirect);
            setNextStepAsActive(quest);
            break;
          }
          case "video": {
            _setActiveQuest(quest);

            if (!step.videoLink) {
              throw new Error("Must provide a video embed link to play video");
            }

            showModal(QuestVideoPlayerModal, { step });
            break;
          }
          case "action":
          case "orb": {
            _setActiveQuest(quest);
          }
        }
      }
    } else {
      _setActiveQuest(undefined);
    }
  });

  const onStepComplete = useCallbackSafeRef(() => {
    if (!activeQuest) return;

    const next = getNextQuestStep(activeQuest);
    if (next) {
      setActiveQuest({ ...activeQuest, step: next.id });
    } else {
      void completeQuest(activeQuest);
    }
  });

  return { setActiveQuest, completeQuest, onStepComplete, loadQuests, updateQuests };
};
