import { DayOfWeekStr } from "../reclaim-api/Calendars";

export const DAYS_OF_WEEK: readonly DayOfWeekStr[] = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

export const DAYS_OF_WEEK_META: Readonly<Record<DayOfWeekStr, Readonly<{ label: string }>>> = {
  SUNDAY: { label: "Sunday" },
  MONDAY: { label: "Monday" },
  TUESDAY: { label: "Tuesday" },
  WEDNESDAY: { label: "Wednesday" },
  THURSDAY: { label: "Thursday" },
  FRIDAY: { label: "Friday" },
  SATURDAY: { label: "Saturday" },
};
