import { filter, map, pipe } from "wonka";
import { sourceT } from "wonka/dist/types/src/Wonka_types.gen";
import { BUILD_ID } from "../../analytics";
import { Override } from "../../types";
import { strToDate } from "../../utils/dates";
import { getBillingUrl } from "../../utils/router";
import { Envelope, SubscriptionType } from "../adaptors/ws";
import { TransformDomain } from "../types";
import {
  DomainSetting as DomainSettingDto,
  JoinableTeam as JoinableTeamDto,
  MembershipRole as MembershipRoleDto,
  PartialTeam as PartialTeamDto,
  PartialTeamInvitation as PartialTeamInvitationDto,
  PartialTeamMember as PartialTeamMemberDto,
  ReclaimApi,
  ReclaimEdition as ReclaimEditionDto,
  RequestParams,
  TeamDomainView as TeamDomainViewDto,
  TeamInvitation as TeamInvitationDto,
  TeamInvoice as TeamInvoiceDto,
  TeamJoinResponse as TeamJoinResponseDto,
  TeamMemberView as TeamMemberViewDto,
  TeamSettings as TeamSettingsDto,
  TeamView as TeamViewDto,
} from "./client";
import {
  dtoToEntitlementTable,
  dtoToReclaimEdition,
  dtoToSubscriptionChangeResult,
  dtoToSubscriptionOptions,
  dtoToTeamMembershipSummary,
  dtoToTeamPricingSummary,
  reclaimEditionToDto,
  subscriptionChangeToDto,
  supportedCurrencyToDto,
} from "./Team.mutators";
import {
  ReclaimEdition,
  SubscriptionChange,
  SubscriptionFrequencyStr,
  SupportedCurrency,
  TeamMembershipSummary,
  TeamPricingSummary,
} from "./Team.types";

const API_BASE_URI = process.env.NEXT_PUBLIC_API_BASE_URI;

const TeamSubscription = {
  subscriptionType: SubscriptionType.Team,
};

export type TeamInvoice = Override<TeamInvoiceDto, {}>;
export const STRIPE_SESSION_URI = `${API_BASE_URI}/team/current/subscription/session`;
export const STRIPE_NEW_SESSION_URI = `${API_BASE_URI}/team/create/subscription/session`;

export enum InvitationStatus {
  Pending = "PENDING",
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
  Deleted = "DELETED",
}

export enum TeamMemberViewStatus {
  Pending = "PENDING",
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
}

export enum MembershipRole {
  User = "USER",
  Admin = "ADMIN",
}

export const MembershipRoleLabel: Record<MembershipRole, string> = {
  ADMIN: "Admin",
  USER: "User",
};

export enum SubscriptionStatus {
  Trialing = "TRIALING",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export enum DomainSetting {
  InviteOnly = "INVITE_ONLY",
  Request = "REQUEST",
  Open = "OPEN",
  AutoDomainCapture = "AUTO_DOMAIN_CAPTURE",
}

export type DomainSettingStr = `${DomainSettingDto}`;

export enum RequestedTeamStatus {
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export enum InviteSource {
  Default = "invite",
  CreateTeam = "create-team",
}

export type TeamMember = Override<
  TeamMemberViewDto,
  {
    readonly edition: ReclaimEdition;
    readonly editionAfterTrial: ReclaimEdition;
    readonly status: TeamMemberViewStatus;
    readonly membershipRole: MembershipRole;
    readonly trialEnd?: Date;
    readonly requestedTeamStatus?: RequestedTeamStatus;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly name?: string;
    readonly editionUsage: ReclaimEdition;
    readonly paidSeat?: boolean | null;
    /**
     * @deprecated use `trial` instead.
     */
    readonly trialSeat?: boolean | null;
    // This field is expected based on the swagger definition but
    // it can actually be undefined if the user hasn't onboarded with
    // scheduling links. TODO: RAI-6959
    readonly schedulingLinkSlug?: string;
  }
>;

export type TeamSettings = Override<TeamSettingsDto, {}>;

export type Team<HOMOGENEOUS extends boolean = boolean> = Override<
  Omit<TeamViewDto, "homogeneous" | "teamEdition" | "entitlements" | "subscriptionStatus" | "subscriptionFrequency">,
  {
    readonly id?: number;
    /**
     * @deprecated legacy pricing plans only
     */
    readonly userInviteLevel: ReclaimEdition;
    /**
     * @deprecated use `pricingSummary.seatCounts` instead.
     */
    readonly paidProSeats: number;
    /**
     * @deprecated use `pricingSummary.seatCounts` instead.
     */
    readonly paidTeamSeats: number;
    /**
     * @deprecated use `pricingSummary.seatCounts` instead.
     */
    readonly proSeatsUsed: number;
    /**
     * @deprecated use `pricingSummary.seatCounts` instead.
     */
    readonly teamSeatsUsed: number;
    readonly members: TeamMember[];
    readonly domainSetting: DomainSetting;
    readonly teamSettings: TeamSettings;
    readonly membershipSummary: TeamMembershipSummary;
    readonly pricingSummary: TeamPricingSummary<HOMOGENEOUS>;
    readonly adminCount: number;
  }
>;

export type PartialTeam = Override<
  PartialTeamDto,
  {
    userInviteLevel?: ReclaimEdition | null;
    domainSetting?: DomainSetting;
  }
>;

export type JoinableTeam = Override<
  JoinableTeamDto,
  {
    readonly domainSetting: DomainSetting;
  }
>;

export type TeamJoinResponse = Override<
  TeamJoinResponseDto,
  {
    role?: MembershipRole;
    editionAfterTrial?: ReclaimEdition;
  }
>;

export type PartialTeamMember = Override<
  PartialTeamMemberDto,
  {
    edition?: ReclaimEdition;
    role?: MembershipRole;
  }
>;

export type PartialTeamInvitation = Override<
  PartialTeamInvitationDto,
  {
    role: MembershipRole;
    edition?: ReclaimEdition;
  }
>;

export type TeamInvitation = TeamInvitationDto;

export type TeamDomainView = TeamDomainViewDto;

export enum SubscriptionFrequency {
  Month = "MONTH",
  Year = "YEAR",
}

export type DesiredSubscription = {
  frequency: SubscriptionFrequency | SubscriptionFrequencyStr;
  seats: number;
  edition: ReclaimEdition;
};

export enum TeamRedirectAction {
  InviteAccepted = "accepted",
  PurchaseSuccess = "purchased",
  PurchaseCancelled = "purchaseCancelled",
  StartPurchase = "startPurchase",
  RequestJoin = "requestJoin",
  LeaveTeam = "leaveTeam",
}

export const dtoToTeamMember = (
  dto: TeamMemberViewDto & { firstName?: string; lastName?: string; name?: string }
): TeamMember => {
  const [dtoFirstName, ...otherNames] = (dto.name || "").split(" ");
  const dtoLastName = otherNames[otherNames.length - 1];

  return {
    ...dto,
    edition: dto.edition ? dtoToReclaimEdition(dto.edition) : "ASSISTANT",
    editionAfterTrial: dto.editionAfterTrial ? dtoToReclaimEdition(dto.editionAfterTrial) : "ASSISTANT",
    status: dto.status as unknown as TeamMemberViewStatus,
    membershipRole: dto.membershipRole as unknown as MembershipRole,
    editionUsage: dto.editionUsage || "ASSISTANT",
    requestedTeamStatus: !!dto.requestedTeamStatus
      ? (dto.requestedTeamStatus as unknown as RequestedTeamStatus)
      : undefined,
    trialEnd: strToDate(dto.trialEnd),
    firstName: dto.firstName || dtoFirstName,
    lastName: dto.lastName || dtoLastName,
  };
};

export const dtoToTeamPartial = (dto: Partial<TeamViewDto>): Partial<Team> => {
  return {
    ...dto,
    paidProSeats: dto.paidProSeats || 0,
    paidTeamSeats: dto.paidTeamSeats || 0,
    proSeatsUsed: dto.proSeatsUsed || 0,
    teamSeatsUsed: dto.teamSeatsUsed || 0,
    userInviteLevel: dto.userInviteLevel ? dtoToReclaimEdition(dto.userInviteLevel) : "NONE",
    members: dto.members?.map(dtoToTeamMember),
    domainSetting: dto.domainSetting as unknown as DomainSetting,
    pricingSummary: dto.pricingSummary && dtoToTeamPricingSummary(dto.pricingSummary),
    membershipSummary: dto.membershipSummary && dtoToTeamMembershipSummary(dto.membershipSummary),
    adminCount: dto.members?.reduce((count, { membershipRole }) => {
      if (membershipRole === MembershipRoleDto.ADMIN) count++;
      return count;
    }, 0),
  };
};

// should always return a full Team as long as the TeamViewDto isn't partial
export const dtoToTeam = (dto: TeamViewDto): Team => dtoToTeamPartial(dto) as Team;

export const dtoToJoinableTeams = (teams: JoinableTeamDto[]): JoinableTeam[] =>
  teams.map((team: JoinableTeamDto) => ({
    ...team,
    domainSetting: team.domainSetting as unknown as DomainSetting,
  }));

export const responsesToDto = (responses: TeamJoinResponse[]): TeamJoinResponseDto[] =>
  responses.map((r) => ({
    ...r,
    role: r.role as unknown as MembershipRoleDto,
    editionAfterTrial: r.editionAfterTrial && reclaimEditionToDto(r.editionAfterTrial),
  }));

export const dtoToTeamInvoice = (dto: TeamInvoiceDto): TeamInvoice => {
  return {
    ...dto,
  };
};

export const invitesToDto = (invites: PartialTeamInvitation[]): PartialTeamInvitationDto[] => {
  return invites.map((i) => ({
    ...i,
    role: i.role as unknown as MembershipRoleDto,
    edition: (i.edition && reclaimEditionToDto(i.edition)) as ReclaimEditionDto,
  }));
};

export const partialTeamMemberToDto = (member: PartialTeamMember): PartialTeamMemberDto => ({
  ...member,
  role: member.role as unknown as MembershipRoleDto,
  edition: (member.edition && reclaimEditionToDto(member.edition)) as ReclaimEditionDto,
});

export const partialTeamToDto = (team: PartialTeam): PartialTeamDto => ({
  ...team,
  userInviteLevel: team.userInviteLevel && reclaimEditionToDto(team.userInviteLevel),
  domainSetting: team.domainSetting as unknown as DomainSettingDto,
});

export class TeamDomain extends TransformDomain<Team, TeamViewDto> {
  /**
   * The team domain currently has its own separate client generation. Use
   * the domainApi instead of api for executing team module requests.
   */
  domainApi: ReclaimApi;

  constructor(...args) {
    super(...args);

    this.domainApi = new ReclaimApi({ baseUrl: API_BASE_URI, BUILD_ID });
  }

  resource = "Team";
  cacheKey = "team";

  get = (): Promise<Team> => this.domainApi.team.getCurrentTeam().then(dtoToTeam);

  teamWs$ = pipe(
    this.ws.subscription$$(TeamSubscription) as sourceT<Envelope<Partial<TeamViewDto>>>,
    filter((envelope) => !!envelope.data),
    map((envelope) => dtoToTeamPartial(envelope.data))
  );

  watchTeam$$ = () => this.teamWs$;

  getMembers = (): Promise<TeamMember[]> =>
    this.domainApi.team.getMembers().then((response: TeamMemberViewDto[]) => response.map(dtoToTeamMember));

  getJoinableTeams = (): Promise<JoinableTeam[]> => this.domainApi.team.getJoinableTeams().then(dtoToJoinableTeams);

  respondToRequests = this.manageErrorsAndNotificationKey(
    (responses: TeamJoinResponse[]): Promise<TeamMember[]> =>
      this.domainApi.team.joinResponses(responsesToDto(responses)).then((r) => r.map(dtoToTeamMember)),
    "respondToRequest",
    {
      errorizer: (cause, responses) =>
        Array.isArray(responses)
          ? `Failed to respond to requests: ${responses.map(({ userId }) => userId).join(", ")}`
          : "Failed to respond to requests: Invalid responses array",
    }
  );

  /**
   * request to join a team
   * @deprecated Not actually deprecated, but use useConfirmChangeTeamDialog instead
   */
  requestToJoinTeam = this.manageErrorsAndNotificationKey(
    (teamId: number) => this.domainApi.team.requestToJoinTeam(teamId),
    "requestToJoinTeam",
    { errorizer: (cause, teamId) => `Failed to request to join team: ${teamId}` }
  );

  getRequests = (): Promise<TeamMember[]> =>
    this.domainApi.team.getRequests().then((r) => r.map(dtoToTeamMember));

  cancelRequestToJoinTeam = this.manageErrorsAndNotificationKey(
    (teamId: number): Promise<void> => this.domainApi.team.cancelRequestToJoinTeam(teamId),
    "cancelRequestToJoinTeam",
    { errorizer: (cause, teamId) => `Failed to cancel request to join for team: ${teamId}` }
  );

  getInviteableTeamMembers = (): Promise<TeamMember[]> =>
    this.domainApi.team.getInviteableTeamMembers().then((r) => r.map(dtoToTeamMember));

  listInvoices = (): Promise<TeamInvoice[]> => this.domainApi.team.listInvoices().then((r) => r.map(dtoToTeamInvoice));

  deleteMember = this.manageErrorsAndNotificationKey(
    (userId: string) => this.domainApi.team.deleteMember({ userId }),
    "deleteMember",
    { errorizer: (cause, userId) => `Failed to delete team member: ${userId}` }
  );

  invite = this.manageErrorsAndNotificationKey(
    async (invites: PartialTeamInvitation[], source?: InviteSource) => {
      const dtoinvites = invitesToDto(invites);
      return this.typedManageErrors(() => this.domainApi.team.createInvitation(dtoinvites, { source }))();
    },
    "invite",
    {
      errorizer: (cause, invites) =>
        Array.isArray(invites)
          ? `Failed to invite users: ${invites.map(({ email }) => email).join(", ")}`
          : "Failed to invite users: Invalid invites array",
    }
  );

  /**
   * request to join a team
   * @deprecated Not actually deprecated, but use useConfirmChangeTeamDialog instead
   */
  leaveTeam = this.manageErrorsAndNotificationKey(() => this.domainApi.team.leaveTeam(), "");

  patchTeam = this.manageErrorsAndNotificationKey(
    (data: PartialTeam, params?: RequestParams) => this.domainApi.team.patchTeam(partialTeamToDto(data), params),
    "patchTeam",
    { errorizer: (cause, { name }) => `Problem patching team: ${name}` }
  );

  patchMember = this.manageErrorsAndNotificationKey(
    (userId: string, data: PartialTeamMember, params?: RequestParams) =>
      this.domainApi.team.patchMember({ userId }, partialTeamMemberToDto(data), params),
    "patchMember",
    { errorizer: (cause, userId) => `Problem patching team member: ${userId}` }
  );

  getInvitations = (): Promise<TeamInvitation[]> => this.domainApi.team.getInvitations();

  getDomains = (): Promise<TeamDomainView[]> => this.domainApi.team.getCurrentTeamDomains();

  /**
   * request to join a team
   * @deprecated Not actually deprecated, but use useConfirmChangeTeamDialog instead
   */
  acceptInvitation = this.manageErrorsAndNotificationKey(
    (teamId: number, inviteId: string) => this.domainApi.team.acceptInvitation(teamId, inviteId, { accept: true }),
    "acceptInvitation",
    { errorizer: (cause, teamId, inviteId) => `Failed to accept invitation with ID ${inviteId} to team "${teamId}"` }
  );

  deleteInvitation = this.manageErrorsAndNotificationKey(
    (id: string) => this.domainApi.team.deleteInvitation(id),
    "deleteInvitation",
    { errorizer: (cause, id) => `Failed to delete invitation: ${id}` }
  );

  previewSubscriptionChange = this.typedManageErrors(async (subChange: SubscriptionChange) =>
    dtoToSubscriptionChangeResult<true>(
      await this.domainApi.team.previewSubscriptionChange(subscriptionChangeToDto(subChange))
    )
  );

  subscriptionChange = this.manageErrorsAndNotificationKey(
    async (subChange: SubscriptionChange) =>
      dtoToSubscriptionChangeResult<true>(
        await this.domainApi.team.subscriptionChange(subscriptionChangeToDto(subChange))
      ),
    "subscriptionChange"
  );

  cancelSubscription = this.manageErrorsAndNotificationKey(
    async (reason?: string) => await this.domainApi.team.cancelSubscription({ reason }),
    "cancelSubscription"
  );

  reinstateSubscription = this.manageErrorsAndNotificationKey(
    async () => await this.domainApi.team.reinstateSubscription(),
    "reinstateSubscription"
  );

  endTrial = this.manageErrorsAndNotificationKey(async () => await this.domainApi.team.endTrial(), "endTrial");

  startSubscription = this.manageErrorsAndNotificationKey(
    async (
      { frequency, seats, edition }: DesiredSubscription,
      options: { newTab?: boolean; currency?: SupportedCurrency } = {}
    ) => {
      const { newTab = false, currency = "USD" } = options;
      const baseRedirect = `${window.location.origin}${getBillingUrl()}`;
      const url = new URL(STRIPE_NEW_SESSION_URI);

      url.search = new URLSearchParams({
        successUrl: `${baseRedirect}?action=${TeamRedirectAction.PurchaseSuccess}`,
        cancelUrl: `${baseRedirect}?action=${TeamRedirectAction.PurchaseCancelled}`,
        seats: seats.toString(),
        edition,
        frequency,
        currency: supportedCurrencyToDto(currency),
      }).toString();

      if (!!newTab) {
        window.open(url.toString(), "billing_subscription");
      } else {
        window.location.href = url.toString();
      }
    },
    "startSubscription"
  );

  getEntitlementTable = this.typedManageErrors(async () => dtoToEntitlementTable(await this.domainApi.team.editions()));

  getSubscriptionOptions = this.typedManageErrors(async (quantity: number, currency?: SupportedCurrency) =>
    dtoToSubscriptionOptions(
      await this.domainApi.team.subscriptionOptions({
        quantity,
        currency: currency ? supportedCurrencyToDto(currency) : null,
      })
    )
  );
}
