import { Annotation } from "./client";
import { IntegrationsEnabledMap } from "./Integrations.types";

// incoming object doesn't include future
// integrations.  To be sure everything gets
// a boolean we're filling everything with a
// false.
const DEFAULT_ENABLED_MAP: Readonly<IntegrationsEnabledMap> = {
  ADD_ON: false,
  ASANA: false,
  CLICKUP: false,
  GOOGLE_TASKS: false,
  JIRA: false,
  LINEAR: false,
  MONDAY: false,
  OFFICE365: false,
  SLACK: false,
  TODOIST: false,
  TRELLO: false,
  ZOOM: false,
};

export const dtoToIntegrationsEnabledMap = (dto: Readonly<Annotation>): Readonly<IntegrationsEnabledMap> => ({
  ...DEFAULT_ENABLED_MAP,
  ...dto,
});
