import { leftPadTo } from "./strings";

export function formatFloat(decimal: number, places: number) {
  if (isNaN(decimal)) return 0;
  return Number.parseFloat(`${Math.round(Number.parseFloat(`${decimal}e+${places}`))}e-${places}`);
}

export function isNumeric(str: string): boolean {
  return /^-?\d+$/.test(str);
}

export const roundToDecimalPlaces = <N extends boolean>(
  value: number,
  decimals: number,
  numeric?: N
): N extends true ? number : string => {
  const str = (Math.round(value * 100) / 100).toFixed(decimals);
  return (numeric ? Number(str) : str) as N extends true ? number : string;
};

export default [formatFloat];

export const addLeadingZeroes = (num: number, minSize: number) => leftPadTo(`${num}`, minSize, "0");

export const isEven = (num: number) => Math.round(num / 2) === num / 2;
export const isOdd = (num: number) => !isEven(num);
