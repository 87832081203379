import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  RadioGroup,
  RadioGroupProps,
} from "@mui/material";
import { useCallback } from "react";
import { FieldValues } from "react-hook-form";
import { IRhfControl } from ".";
import { isNumeric } from "../../../utils/numbers";
import { RhfControlled, RhfControlledProps, RhfControlledRenderProps } from "./RhfControlled";

export type RadioGroupControlRenderProps = RhfControlledRenderProps &
  FormControlProps & {
    RadioGroupProps?: RadioGroupProps;
  };

export type RhfRadioGroupControlProps = FormControlProps &
  Omit<RhfControlledProps<FieldValues, RadioGroupControlRenderProps>, "render"> & {
    label?: string;
    RadioGroupProps?: RadioGroupProps;
  };

export const RhfRadioGroupControl: IRhfControl<RhfRadioGroupControlProps> = ({ name, label, ...rest }) => {
  const render = useCallback(
    ({
      name,
      field,
      fieldState,
      formState,
      RadioGroupProps = {},
      error,
      children,
      ...rest
    }: RadioGroupControlRenderProps) => {
      const { onChange, ...inputProps } = field;

      const handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        // Handle booleans set on control value.
        if (e.target.value === "true" || e.target.value === "false") {
          void onChange(e.target.value === "true");
        } else if (isNumeric(e.target.value as string)) {
          void onChange(+(e.target.value as string));
        } else {
          void onChange(e);
        }
      };

      return (
        <FormControl variant="standard" {...rest} error={!!fieldState.error}>
          {!!label && <InputLabel>{label}</InputLabel>}
          <RadioGroup {...RadioGroupProps} {...inputProps} onChange={handleChange}>
            {children}
          </RadioGroup>
          {!!fieldState.error && (<FormHelperText error>{fieldState.error.message}</FormHelperText>)}
        </FormControl>
      );
    },
    [label]
  );

  return <RhfControlled name={name} {...rest} render={render} />;
};

RhfRadioGroupControl.isControl = true;
RhfRadioGroupControl.isController = true;
