import { Button, ButtonProps } from "@mui/material";
import { createElement, forwardRef, VFC } from "react";
import { Link } from "./Link";

export type ButtonWithLinkVariantProps = Omit<ButtonProps, "variant"> & {
  variant?: ButtonProps["variant"] | "link";
};

export const ButtonWithLinkVariant: VFC<ButtonWithLinkVariantProps> = forwardRef<
  HTMLButtonElement,
  ButtonWithLinkVariantProps
>(({ variant, children, ...rest }, ref) =>
  createElement(
    (variant === "link" ? Link : Button) as typeof Button,
    {
      variant: variant === "link" ? undefined : variant,
      ref,
      ...rest,
    },
    children
  )
);
