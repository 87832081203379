import { Box, CircularProgress, CircularProgressProps } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { VFC } from "react";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexShrink: 0,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      align: "center",
      verticalAlign: "middle",
    },
    spinner: {},
    circle: {
      strokeLinecap: "round",
    },
  }),
  { classNamePrefix: "LoadingIcon" }
);

export type LoadingIconProps = CircularProgressProps & {};

export const LoadingIcon: VFC<LoadingIconProps> = ({ className, size = 18, ...rest }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)}>
      <CircularProgress size={size} className={classes.spinner} classes={{ circle: classes.circle }} {...rest} />
    </Box>
  );
};
