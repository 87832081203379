import { IconButton, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles';
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import { SnackbarContent } from "notistack";
import { createElement, forwardRef, useMemo } from "react";
import { useQuestsActions, useQuestsState } from "../../hooks/atoms/useQuests";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { useOurRouter } from "../../hooks/useOurRouter";
import { getQuestsUrl } from "../../utils/router";
import { Link } from "../Link";
import { GenericQuestConfig } from "./quests.types";
import { getGroupConfigFromQuestId, getNextQuestFromGroup } from "./quests.util";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.logo.corn,
      borderRadius: theme.shape.borderRadius * 2.5,
      width: 300,
      padding: theme.spacing(1.5),
    },
    title: {
      paddingRight: theme.spacing(4),
    },
    close: {
      position: "absolute",
      top: theme.spacing(0.25),
      right: theme.spacing(0.5),
      padding: theme.spacing(1),
    },
    closeIcon: {
      color: theme.palette.common.black,
      width: 16,
      height: 16,
    },
    content: {
      display: "flex",
      flexDirection: "column",
    },
    nextUp: {
      marginTop: theme.spacing(1),
    },
    nextTitle: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    nextUpItem: {
      backgroundColor: "rgba(255, 255, 255, .2)",
      padding: theme.spacing(),
      marginTop: theme.spacing(0.5),
      display: "flex",
      alignItems: "start",
      borderRadius: theme.shape.borderRadius * 2,
      gap: theme.spacing(),
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "rgba(255, 255, 255, .3)",
      },
    },
    nextUpIcon: {
      flexShrink: 0,
      height: 24,
      opacity: 0.7,
      width: 24,
    },
    nextUpItemText: {},
    groupMessage: {},
  }),
  {
    classNamePrefix: "QuestNotification",
  }
);

export type QuestNotificationJSSClassKey = keyof ReturnType<typeof useStyles>;

export type QuestNotificationProps = {
  classes?: Partial<ClassNameMap<QuestNotificationJSSClassKey>>;
  className?: string;
  quest: GenericQuestConfig;
  onClose: () => void;
};

export const QuestNotification = forwardRef<HTMLDivElement, QuestNotificationProps>(
  ({ className, classes: extClasses, quest, onClose }, ref) => {
    const classes = useStyles({
      classes: extClasses,
    });

    const router = useOurRouter();
    const { setActiveQuest } = useQuestsActions();
    const { quests } = useQuestsState();

    const nextQuest = useMemo(() => (quests ? getNextQuestFromGroup(quests, quest.id) : undefined), [quests, quest.id]);
    const questGroup = useMemo(() => getGroupConfigFromQuestId(quest.id), [quest.id]);

    const handleStartNextQuest = useCallbackSafeRef(() => {
      if (!nextQuest) return;

      setActiveQuest({ group: nextQuest.groupId, quest: nextQuest.id, step: nextQuest.steps[0].id });

      if (nextQuest.entryPathname) {
        void router.push(nextQuest.entryPathname);
      }

      onClose();
    });

    const handleGroupCompleteClick = useCallbackSafeRef(() => {
      void router.push(getQuestsUrl());
      onClose();
    });

    return (
      <SnackbarContent ref={ref} className={clsx(classes.root, className)}>
        <IconButton
          aria-label="close"
          className={classes.close}
          onClick={onClose}
          size="large">
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <div className={classes.content}>
          {!!nextQuest && (
            <>
              <Typography className={classes.title} variant="body2">
                You've completed "{quest.title}"
              </Typography>
              <div className={classes.nextUp} onClick={handleStartNextQuest}>
                <Typography variant="body2">
                  <b>Next up</b>
                </Typography>
                <div className={classes.nextUpItem}>
                  {createElement(nextQuest.icon, { className: classes.nextUpIcon })}
                  <div className={classes.nextUpItemText}>
                    <Typography variant="body2" className={classes.nextTitle}>
                      {nextQuest.title}
                    </Typography>
                    <Typography variant="body2">{nextQuest.description}</Typography>
                  </div>
                </div>
              </div>
            </>
          )}
          {!nextQuest && !!questGroup && (
            <Typography variant="body2" className={classes.groupMessage}>
              You've completed everything in "{questGroup.title}."{" "}
              <Link color="inherit" onClick={handleGroupCompleteClick}>
                Keep exploring
              </Link>
            </Typography>
          )}
        </div>
      </SnackbarContent>
    );
  }
);
