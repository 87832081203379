import { useCallback, useEffect } from "react";
// eslint-disable-next-line no-restricted-imports
import { useIntercom as useIntercomLib } from "react-use-intercom";
import { Link } from "../components/Link";
import { SUPPORT_EMAIL } from "../consts/emails";
import { useUserContext } from "../context/UserContext";
import { useNotifications } from "./useNotifications";

export type UseOpenIntercomReturnType = {
  /**
   * Opens Intercom with an optional message.
   * @param message The message to open Intercom with.
   */
  showIntercom(message?: string): void;
};

export const useIntercom = (defaultMessage?: string): UseOpenIntercomReturnType => {
  const { boot, show, showNewMessage } = useIntercomLib();

  const [{ user }] = useUserContext();

  const { sendNotification } = useNotifications();

  const showIntercom = useCallback<UseOpenIntercomReturnType["showIntercom"]>(() => {
    if (!window.Intercom.booted) {
      sendNotification({
        message: (
          <>
            Couldn't load Intercom. Try sending us an email at{" "}
            <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>. Want to know more? Check out our{" "}
            <Link href="https://help.reclaim.ai/en/articles/6338330">help docs</Link>.
          </>
        ),
        type: "warning",
      });
    } else if (!!defaultMessage) {
      showNewMessage(defaultMessage);
    } else {
      show();
    }
  }, [showNewMessage, defaultMessage, show, sendNotification]);

  useEffect(() => {
    if (user) {
      boot({
        name: user.name,
        email: user.email,
        userId: user.trackingCode,
        hideDefaultLauncher: true,
        customLauncherSelector: "#IntercomHelpButton",
      });
    }
  }, [boot, user]);

  return {
    showIntercom,
  };
};
