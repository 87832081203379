// eslint-disable-next-line no-restricted-imports
import {
  DateCalendar as MuiDateCalendar,
  DateCalendarProps as MuiDateCalendarProps
} from "@mui/x-date-pickers/DateCalendar";
import { DateCalendarStylesWrapper } from "./DateCalendarStylesWrapper";

export const DateCalendar = <T_DATE extends unknown>({
  views = ["day"],
  ...rest
}: MuiDateCalendarProps<T_DATE>) => (
  <DateCalendarStylesWrapper>
    <MuiDateCalendar
      views={views}
      {...rest}
    />
  </DateCalendarStylesWrapper>
);
