import { VFC } from "react";
import { useUserContext } from "../context/UserContext";
import { EasyConfirmDialog } from "./EasyConfirmDialog";
import { EasyLogWorkDialog } from "./EasyLogWork";
import { EasyReschedulator } from "./EasyReschedulator";
import { QuestsNotifications } from "./quests/QuestNotifications";

// TODO: all of these components are 90% the same code.  There should be an easy way to make this fairly generic.
export const EasyAccessElements: VFC = () => {
  const [{ isAuthenticated }] = useUserContext();

  return (
    <>
      {isAuthenticated && <QuestsNotifications />}
      <EasyReschedulator />
      <EasyConfirmDialog />
      <EasyLogWorkDialog />
    </>
  );
};
