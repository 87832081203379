import { extantNativeMap, walk } from "./objects";

export const randomInArray = <T>(arr: T[]): T => arr[Math.floor(Math.random() * arr.length)];

/**
 * Equivilant of [].filter(i => !!i) but correctly removes falsy values from from the type
 * @param items An array of items
 * @returns The same array without falsy values
 */
export const filterFalsy = <T>(items?: T[]) =>
  (items?.filter((i) => !!i) || []) as Exclude<T, null | undefined | false | 0 | "">[];

export function range(size: number): number[];
export function range(from: number, to?: number, step?: number): number[];
/**
 * Generates a list of values between two numbers
 *
 * @param from starting index (inclusive)
 * @param to ending index (exclusive)
 * @param step defaults to 1
 */
export function range(from: number, to?: number, step: number = 1): number[] {
  const [start, end] = undefined !== to ? [from, to / step] : [0, from / step];
  return Array.from(Array(end - start), (_, i) => i * step + start);
}

/**
 * Check if two arrays contain all the same elements (regardless of order)
 *
 * @param a
 * @param b
 */
export function arrequal<T>(a: T[], b: T[]): boolean {
  if (!a || !b || a.length !== b.length) return false;
  return !a.some((o, idx) => !b[idx] || b[idx] !== o);
}

export function arrdupes<T>(a: T[]): boolean {
  return new Set(a).size !== a.length;
}

export function arrdiff<T>(a: T[], b: T[]) {
  const diff = (a: T[], b: T[]) => a.filter((item) => !b.includes(item));
  return [...diff(a, b), ...diff(b, a)];
}

export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) return l;
  }
  return -1;
}

export function byKey<T = unknown>(key: string) {
  return (a: T, b: T) => {
    if (walk(key, a) === walk(key, b)) return 0;
    return (
      typeof walk(key, a) === "string"
        ? walk(key, a).toLowerCase() < walk(key, b).toLowerCase()
        : walk(key, a) < walk(key, b)
    )
      ? -1
      : 1;
  };
}

/**
 * Modifes `arr` in place, removing the found item and returning it
 * @param arr The array to search
 * @param find The function used to search the array
 */
export function findAndPunch<T>(arr: T[], find: (item: T, index: number) => boolean): T | undefined {
  const index = arr.findIndex(find);
  if (index === -1) return;
  return arr.splice(index, 1)[0];
}

/**
 * Modifies `arr` in place.  Sends the found item to the front of the array
 * @param arr
 * @param find
 * @returns The moved item
 */
export function moveToFront<T>(arr: T[], find: (item: T, index: number) => boolean): T | undefined {
  const item = findAndPunch(arr, find);
  if (item) arr.unshift(item);
  return item;
}

export function transformMap<T, N>(thing: null, transform?: (orig: T) => N): null;
export function transformMap<T, N>(thing: T, transform?: (orig: T) => N): N;
export function transformMap<T, N>(thing: T[], transform?: (orig: T) => N): N[];
export function transformMap<T, N>(thing: T | T[] | null, transform?: (orig: T) => N) {
  if (!thing) return null;
  if (!transform) return thing;

  if (Array.isArray(thing)) {
    return thing.map(transform);
  } else {
    return transform(thing);
  }
}

/**
 * Rotates array around index, putting everything before index after everything after.
 * @param arr The array to rotate
 * @param index The index to rotate around
 * @returns A copy of `arr` rotated.
 */
export const rotateArrayAtIndex = <T>(arr: readonly T[], index: number): T[] => {
  const second = [...arr];
  const first = second.splice(0, index);
  return [...second, ...first];
};

/**
 * Rotates array around an index found using `predicate`, putting everything before index after everything after.
 * @param arr The array to rotate
 * @param predicate A finding function
 * @returns A copy of `arr` rotated.  If predicate does not find any items, an unmodified copy of the array will be returned
 */
export const rotateAroundFind = <T>(arr: readonly T[], predicate: (item: T) => boolean): T[] => {
  const index = arr.findIndex(predicate);
  if (index < 0) return [...arr];
  return rotateArrayAtIndex(arr, index);
};

/**
 * Rotates array around the first instance of `item`, putting everything before index after everything after.
 * @param arr The array to rotate
 * @param item An item in the array
 * @returns A copy of `arr` rotated.  If no matching item is found, an unmodified copy of the array will be returned
 */
export const rotateAroundFirstInstance = <T>(arr: readonly T[], item: T): T[] =>
  rotateAroundFind(arr, (itItem) => itItem === item);

/**
 * Removes duplicates from array
 * @param arr An array
 * @returns The same array without duplicate values
 */
export const makeExclusive = <T>(arr: readonly T[]): T[] => Array.from(new Set(arr));

export const makeValidItemFilter = <T>(validItems: T[]): ((item: T) => boolean) => {
  const itemMap = extantNativeMap(validItems);
  return (item) => !!itemMap.get(item);
};
