import { ClickAwayListener, ClickAwayListenerProps } from "@mui/material";
import React, { FC, useCallback, useRef } from "react";
import { isDescendantOfEl } from "../utils/dom";

export type SmartClickAwayListenerEventHandler = (e: MouseEvent | TouchEvent, clickAwayHandled: () => void) => void;

export type SmartClickAwayListenerProps = Omit<ClickAwayListenerProps, "onClickAway"> & {
  onClickAway: SmartClickAwayListenerEventHandler;
  estrangedChildren?: HTMLElement[];
};

type SmartClickAwayListenerEvent = (MouseEvent | TouchEvent) & {
  smartClickAwayListenerEventHandled: boolean;
};

export function smartClickAwayEventIsHandled(e: MouseEvent | TouchEvent): boolean {
  return (e as SmartClickAwayListenerEvent).smartClickAwayListenerEventHandled || false;
}

export const SmartClickAwayListener: FC<SmartClickAwayListenerProps> = ({
  children,
  onClickAway,
  estrangedChildren,
  ...rest
}) => {
  const expectedEstrangedChildrenCount = useRef(estrangedChildren?.length).current;

  if (expectedEstrangedChildrenCount !== estrangedChildren?.length)
    throw new Error(
      `estrangedChildren length must not change (expected ${expectedEstrangedChildrenCount || 0}, got ${
        estrangedChildren?.length || 0
      })`
    );

  const handleClickAway = useCallback<ClickAwayListenerProps["onClickAway"]>(
    (e) => {
      if (smartClickAwayEventIsHandled(e)) return;
      if (
        e.target instanceof Node &&
        !(estrangedChildren || []).every((child) => isDescendantOfEl(child, e.target as Node))
      )
        return;

      onClickAway(e, () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e as SmartClickAwayListenerEvent).smartClickAwayListenerEventHandled = true;
      });
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [onClickAway, ...(estrangedChildren || [])]
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway} {...rest}>
      {children}
    </ClickAwayListener>
  );
};
