import { dateToStr, strToDate } from "../utils/dates";
import { DayOfWeek } from "./Calendars";
import {
  DailyHabit as HabitDto,
  DayOfWeek as DayOfWeekDto,
  DefenseAggression as DefenseAggressionDto,
  Recurrence as RecurrenceDto,
  TimePolicyType as TimePolicyTypeDto,
} from "./client";
import { Category, EventColor, EventSubType, PrimaryCategory } from "./EventMetaTypes";
import { DefenseAggression, Habit } from "./Habits";
import { Recurrence } from "./OneOnOnes.types";
import { TimePolicyType } from "./TimeSchemes.types";
import { dtoToTimePolicy, timePolicyToDto } from "./TimeSchemes.mutators";
import { nullable } from "./types";

export const dtoToTimePolicyType = (type: TimePolicyTypeDto): TimePolicyType => type as TimePolicyType;
export const timePolicyTypeToDto = (type: TimePolicyType): TimePolicyTypeDto => type as TimePolicyTypeDto;

export const dtoToHabit = (dto: HabitDto): Habit => ({
  ...dto,
  eventCategory: Category.get(dto.eventCategory as unknown as string) as PrimaryCategory,
  eventColor: !!dto.eventColor ? EventColor.get(dto.eventColor) : EventColor.Auto,
  recurrence: !!dto.recurrence ? Recurrence.get(dto.recurrence) : null,
  idealDay: dto.idealDay as unknown as DayOfWeek,
  snoozeUntil: nullable(dto.snoozeUntil, strToDate),
  timesPerPeriod: dto.timesPerPeriod,
  defenseAggression: dto.defenseAggression as unknown as DefenseAggression,
  created: strToDate(dto.created),
  updated: strToDate(dto.updated),
  eventSubType: EventSubType.get(dto.eventSubType),
  timePolicyType: dto.timePolicyType ? dtoToTimePolicyType(dto.timePolicyType) : undefined,
  oneOffPolicy: dto.oneOffPolicy ? dtoToTimePolicy(dto.oneOffPolicy) : null,
});

export const habitToDto = (habit: Partial<Habit>): Partial<HabitDto> => {
  const dto: Partial<HabitDto> = {
    ...habit,
    eventCategory: habit.eventCategory?.toJSON() as unknown as HabitDto["eventCategory"],
    eventColor: (EventColor.Auto === habit.eventColor ? null : habit.eventColor?.toJSON()) as HabitDto["eventColor"],
    recurrence: (habit.recurrence === null ? null : habit.recurrence?.key) as RecurrenceDto,
    idealDay: habit.idealDay as unknown as DayOfWeekDto,
    snoozeUntil: nullable(habit.snoozeUntil, dateToStr),
    timesPerPeriod: habit.timesPerPeriod,
    defenseAggression: habit.defenseAggression as unknown as DefenseAggressionDto,
    created: dateToStr(habit.created),
    updated: dateToStr(habit.updated),
    eventSubType: habit.eventSubType?.key,
    timePolicyType: habit.timePolicyType ? timePolicyTypeToDto(habit.timePolicyType) : undefined,
    oneOffPolicy: habit.oneOffPolicy ? timePolicyToDto(habit.oneOffPolicy) : undefined,
  };

  if (habit.oneOffPolicy === null) dto.oneOffPolicy = null;

  return dto;
};
