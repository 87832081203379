import { useEffect } from "react";
import { useUserContext } from "../context/UserContext";

declare global {
  interface Window {
    _hsq?;
  }
}

export const useHubspotIdentify = (): void => {
  const [userState] = useUserContext();

  useEffect(() => {
    if (!!userState.isAuthenticated) {
      const _hsq = (window._hsq = window._hsq || []);

      _hsq.push([
        "identify",
        {
          email: userState.user?.email,
          id: userState.user?.trackingCode,
        },
      ]);

      _hsq.push([
        "trackEvent",
        {
          id: "authed_login",
        },
      ]);
    }
  }, [userState.isAuthenticated, userState.user?.email, userState.user?.trackingCode]);
};
