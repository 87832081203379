/// <reference types="@sentry/types" />

import * as Sentry from "@sentry/nextjs";

export const BUILD_ID =
  process.env.NEXT_PUBLIC_BUILD_ID ||
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ||
  process.env.NEXT_PUBLIC_VERCEL_ENV ||
  "unknown";
export const ENV = process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.NODE_ENV;

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

const ready: Record<string, boolean | undefined> = {
  sentry: undefined,
};

function initSentry() {
  if (undefined !== ready.sentry) return ready.sentry;

  if (!!SENTRY_DSN) {
    ready.sentry = false;
    console.debug("Initializing Sentry");

    // initialized automatically w/ configs in `src/sentry.*.js`
    ready.sentry = !!Sentry;
  }

  return true;
}

type Analytics = {
  ready: typeof ready;

  sentry?: typeof Sentry;
};

export const Analytics: Analytics = {
  ready,

  /** Node and browser */
  get sentry() {
    if (!ready.sentry) initSentry();
    return Sentry;
  },
};

export default Analytics;
