import { Override } from "../types/index";
import { browser } from "../utils/platform";
import { Credential as CredentialDto } from "./client";
import { TransformDomain } from "./types";

export class Scopes {
  static OpenId = [
    "openid",
    "https://www.googleapis.com/auth/userinfo.profile",
    "https://www.googleapis.com/auth/userinfo.email",
  ];
  static Calendar = {
    Reduced: ["https://www.googleapis.com/auth/calendar.events", "https://www.googleapis.com/auth/calendar.readonly"],
    Expanded: ["https://www.googleapis.com/auth/calendar"],
  };

  static get(scopes: string[]): string[] {
    return Scopes.OpenId.concat(scopes);
  }
}

export enum PermissionLevel {
  NotEnough,
  Restricted,
  Expanded,
}

export enum AuthMode {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
  Expand = "EXPAND",
  Repair = "REPAIR",
  Tasks = "TASKS",
  ConnectCalendar = "DIRECT_CONNECT_PRIMARY",
}

export type Credential = Override<
  CredentialDto,
  {
    id: number;
    data?: {
      primary?: boolean;
      [key: string]: any;
    };
    permissions: PermissionLevel;
  }
>;

export function dtoToCredential(dto: CredentialDto): Credential {
  const c: Credential = { ...(dto as any) };

  const scopes = c.scopes?.split(/\s+/) || [];
  const expanded = Scopes.get(Scopes.Calendar.Expanded);
  const restricted = Scopes.get(Scopes.Calendar.Reduced);
  // for ui dev, flip cred logic ....
  // c.permissions = !expanded.some((s) => !scopes.includes(s)) ? PermissionLevel.Restricted : PermissionLevel.Expanded;
  c.permissions = !expanded.some((s) => !scopes.includes(s))
    ? PermissionLevel.Expanded
    : restricted.some((s) => scopes.includes(s))
    ? PermissionLevel.Restricted
    : PermissionLevel.NotEnough;

  return c;
}

export function credentialToDto(credential: Partial<Credential>): CredentialDto {
  delete credential.permissions;
  return credential;
}

export class CredentialsDomain extends TransformDomain<Credential, CredentialDto> {
  resource = "Credential";
  cacheKey = "credentials";
  pk = "id";

  public deserialize = dtoToCredential;
  public serialize = credentialToDto;

  list = this.deserializeResponse(this.api.credentials.list1);

  listWithPrimary = this.deserializeResponse((includeInvalid?: boolean) =>
    this.api.credentials.list1({ includeInvalid })
  );

  get = this.deserializeResponse(this.api.credentials.get);

  getPrimary = this.deserializeResponse(this.api.credentials.getPrimary1);

  getPersonal = this.deserializeResponse(this.api.credentials.listPersonal);

  delete = async (credential: Credential) => this.api.credentials.delete2(credential.id);

  save(mode: AuthMode, state?: any) {
    // FIXME: (IW) Doesn't work when base uri is just a path (eg. '/oauth/login'),
    // but window.location is no bueno
    this.redirect("new", mode.toLowerCase(), undefined, state);
  }

  expand(credentialId: number, mode: AuthMode = AuthMode.Expand, state?: any) {
    // FIXME: (IW) Doesn't work when base uri is just a path (eg. '/oauth/login'),
    // but window.location is no bueno
    this.redirect("expand", credentialId, mode, state);
  }

  redirect(action: string, context: string | number, mode?: AuthMode, state?: any) {
    if (!browser().isBrowser) return;

    // FIXME: (IW) Doesn't work when base uri is just a path (eg. '/oauth/login'),
    // but window.location is no bueno
    const authUrl = new URL(`${this.api.baseUrl}/credentials/auth/${action}/${context}`, window.location.href);
    if (mode) authUrl.searchParams.append("mode", mode);
    authUrl.searchParams.append("state", JSON.stringify({ redirect: window.location.pathname, ...(state || {}) }));
    window.location.href = authUrl.toString();
  }

  verifyScopes(credential: Credential, scopes: string[]) {
    if (!credential || !credential.valid || !credential.scopes) return false;
    const existing = credential.scopes.split(/\s+/);
    return Scopes.get(scopes).every((s) => existing.includes(s));
  }
}
