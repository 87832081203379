import { Override } from "../types";
import { ZoomUser as ZoomUserDto } from "./client";
import {
  AttendeeTimePolicyView as AttendeeTimePolicyDto,
  InviteeEligibility as InviteeEligibilityDto,
  OneOnOneInviteeEligibility as OneOnOneInviteeEligibilityDto,
  Recurrence as RecurrenceDto,
  RecurringAssignmentInstance as RecurringAssignmentInstanceDto,
  RecurringOneOnOne as RecurringOneOnOneDto,
} from "./client";
import { ObjectEnum } from "../types/enums";
import { EventStatus } from "./Events";
import { TimePolicy, TimePolicyType } from "./TimeSchemes.types";
import { UserTimezone } from "./Users";
import { ThinPerson } from "./People";
import { Smurf } from "./Tasks";
import { DayOfWeek } from "./Calendars";

export { OneOnOneInviteeEligibilityDto as OneOnOneInviteeEligibility };

export enum RecurringOneOnOneStatus {
  New = "NEW",
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
  Scheduled = "SCHEDULED",
  InviteeError = "INVITEE_ERROR",
}

export enum RecurringAssignmentAttendeeStatus {
  Inviting = "INVITING",
  Pending = "PENDING",
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
  AssumedAccepted = "ASSUMED_ACCEPTED",
}

export enum ConferenceType {
  None = "NONE",
  GoogleMeet = "GOOGLE_MEET",
  Zoom = "ZOOM",
  Custom = "CUSTOM",
}

export enum RedirectActions {
  ResumeEdit = "ResumeEdit",
}
export class Recurrence extends ObjectEnum {
  static Daily = new Recurrence(RecurrenceDto.Daily, "Daily");
  static Weekly = new Recurrence(RecurrenceDto.Weekly, "Weekly");
  static Biweekly = new Recurrence(RecurrenceDto.Biweekly, "Every other week");
  static Monthly = new Recurrence(RecurrenceDto.Monthly, "Monthly");
  static Quarterly = new Recurrence(RecurrenceDto.Quarterly, "Quarterly");

  constructor(public readonly key: string, public readonly label: string) {
    super(key);
  }

  static get Options(): Recurrence[] {
    return Object.values(Recurrence);
  }

  public get dto(): RecurrenceDto {
    return RecurrenceDto[this.key];
  }
}

export type OneOnOne = Override<
  RecurringOneOnOneDto,
  {
    readonly id: number;
    invitee?: ThinPerson;

    idealTime?: string; // comes through in client typed as LocalTime which is just a string
    idealDay?: DayOfWeek | null;
    recurrence: Recurrence;
    organizer?: ThinPerson;

    priority?: Smurf;
    snoozeUntil?: Date | null;

    status: RecurringOneOnOneStatus;
    conferenceType: ConferenceType;
    organizersTimeZone: string;

    organizerTimeSchemeId?: string | null;
    organizerTimePolicyType?: TimePolicyType;
    organizerOneOffPolicy?: TimePolicy | null;

    inviteeTimePolicyType?: TimePolicyType;
    inviteeOneOffPolicy?: TimePolicy | null;

    title: string;

    readonly inviteeTimePolicy: AttendeeTimePolicy;
    readonly organizerTimePolicy: AttendeeTimePolicy;
    readonly effectiveTimePolicy: TimePolicy;
    readonly instances?: RecurringAssignmentInstance[];
    readonly updated?: Date;
  }
>;

export type InviteeEligibility = Override<
  InviteeEligibilityDto,
  {
    oneOnOneInviteeEligibility: OneOnOneInviteeEligibilityDto;
  }
>;

export type AttendeeTimePolicy = Override<
  AttendeeTimePolicyDto,
  {
    timezone: UserTimezone;
    timePolicy: TimePolicy;
    timePolicyInViewerTimezone: TimePolicy;
  }
>;

export type RecurringAssignmentInstance = Override<
  RecurringAssignmentInstanceDto,
  {
    eventKey?: string;
    eventStatus?: EventStatus;

    start?: Date;
    end?: Date;
  }
>;

export type ZoomUser = Override<ZoomUserDto, {}>;
