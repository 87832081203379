import { MouseEventHandler, VFC } from "react";
import { useIntercom } from "../hooks/useIntercom";
import { useCallbackSafeRef } from "../hooks/useCallbackSafeRef";
import { ButtonWithLinkVariant, ButtonWithLinkVariantProps } from "./ButtonWithLinkVariant";

export type ContactSupportButtonProps = Omit<ButtonWithLinkVariantProps, ""> & {
  lead?: string;
};

export const ContactSupportButton: VFC<ContactSupportButtonProps> = ({ children, onClick, lead, ...rest }) => {
  /********************/
  /*   custom hooks   */
  /********************/

  const { showIntercom } = useIntercom(lead);

  /********************/
  /*     useState     */
  /********************/

  /********************/
  /* useMemo & consts */
  /********************/

  /********************/
  /*    useCallback   */
  /********************/

  const handleClick = useCallbackSafeRef<MouseEventHandler<HTMLButtonElement>>(async (e) => {
    onClick?.(e);
    showIntercom();
  });

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return (
    <ButtonWithLinkVariant variant="link" size="small" {...rest} onClick={handleClick}>
      {children || <>Contact&nbsp;us</>}
    </ButtonWithLinkVariant>
  );
};
