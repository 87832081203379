import { EventCategory as EventCategoryDto, TaskDefaults as TaskDefaultsDto } from "./client";
import { TaskDefaults } from "./Tasks";
import { Category, PrimaryCategory } from "./EventMetaTypes";

export const dtoToCategory = (dto: EventCategoryDto): Category => PrimaryCategory.get(dto as string);

export const dtoToTaskDefaults = (dto: TaskDefaultsDto): TaskDefaults => ({
  ...dto,
  category: dtoToCategory(dto.category as EventCategoryDto),
});
