import { addDays, isAfter } from "date-fns";
import { dateToYyyyMmDd } from "../utils/dates";
import { analyticsGroupingIntervalToDto, analyticssMetricToDto, dtoToAnalyticsMetrics } from "./Analytics.mutators";
import { AnalyticsGroupingInterval, AnalyticsMetric, AnalyticsResults } from "./Analytics.types";
import { TransformDomain } from "./types";

export type GetTeamAnalyticsOptions = { groupingInterval?: AnalyticsGroupingInterval } & (
  | { start: Date; end?: Date }
  | { daysIntoPast: number }
);

export class AnalyticsDomain extends TransformDomain<unknown, unknown> {
  resource = "Analytics";
  cacheKey = "analytics";

  getTeamAnalytics = this.typedManageErrors(
    async <T extends AnalyticsMetric[]>(metrics: T, options: GetTeamAnalyticsOptions): Promise<AnalyticsResults<T>> => {
      let start: Date, end: Date;
      const { groupingInterval } = options;

      if ("daysIntoPast" in options) {
        const d = new Date();
        start = addDays(d, -options.daysIntoPast);
        end = d;
      } else {
        start = options.start;
        end = options.end || new Date();
      }

      if (isAfter(start, end)) throw new Error("End cannot come after start");

      return dtoToAnalyticsMetrics(
        await this.api.analytics.analyticsTeamAnalytics({
          start: dateToYyyyMmDd(start),
          end: dateToYyyyMmDd(end),
          metricName: metrics.map(analyticssMetricToDto),
          groupingInterval: analyticsGroupingIntervalToDto(groupingInterval || "DAILY"),
        })
      );
    }
  );
}
