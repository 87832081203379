import { ReclaimEventType, TimePolicyType, TimeScheme } from "./TimeSchemes.types";

export const getEffectiveTimeScheme = (
  timeSchemes: TimeScheme[],
  schemeId?: string,
  policyType?: TimePolicyType
): TimeScheme | undefined => timeSchemes.find((s) => (schemeId ? s.id === schemeId : s.policyType === policyType));

export const filterTimeSchemesByEventType = (eventType: ReclaimEventType, timeSchemes?: TimeScheme[]): TimeScheme[] =>
  timeSchemes?.filter(
    (s) => s.features.includes(eventType) && s.policyType !== "ONE_OFF" && s.policyType !== "INHERITED"
  ) || [];
